import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {getParam} from '../../Util/WindowUtils';
import {withStyles} from '@material-ui/core/styles';
import API from '../../Util/API';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

class GroupSelector extends Component {

 constructor(props) {
  super(props);
  this.state = { q: '', options : [], selected:[]};
 }

 componentDidMount() {
  API.Get('/autocomplete/groups/all').then(res => {
   var gid = getParam('gid', false, false);
   var initial = [];
   if (gid) {
    initial = gid.split(',');
   }
   this.setState({options:res.data, selected:initial});
  }).catch(err => {
   console.log(err);
   this.setState({options:[]});
  });
 }

 handleChange(e) {
  const val = e.target.value;

  const obj = [...this.state.selected];
  var isSelected = this.state.selected.find( (v, s) => v === val);
  if (!isSelected) obj.push(val);
  else {
   obj.filter( (v, s)  => {
    if (v === val) { obj.splice(s, 1); }
    return v;
   });
  }
  this.setState({selected:obj});
  if (this.props.onChange) {
   this.props.onChange(val);
  }
 }

 renderChips(selected) {
  return (
   <div style={{whiteSpace:'pre-wrap'}}>
    {selected.map(i => {
     var value = this.state.options.find( (obj, s) => obj.target_id.toString() === i.toString());
     if (!value) return null;
     return (
      <Chip key={i} label={value.target_label} className={this.props.classes.chip} />
     );
    })}
   </div>
  );
 }

 render() {
  const { classes } = this.props;
  return (
   <FormControl className={classes.formControl}>
    <InputLabel htmlFor="GroupSelector">Group Memberships</InputLabel>
    <Select
     value={this.state.selected}
     onChange={e => this.handleChange(e)}
     renderValue={(selected) => this.renderChips(selected)}
     inputProps={{
      name: 'GroupSelector',
      id: 'GroupSelector',
     }}
    >
     {this.state.options.map(value => (
      <MenuItem value={value.target_id} key={value.target_id}>
       <Checkbox checked={this.state.selected.find(i => i === value.target_id)} />
       {value.target_label}
       <span style={{flexGrow:1}}>&nbsp;</span>
       <img src={value.thumb} alt='thumbnail' style={{maxHeight:50, maxWidth:75, marginRight:4}} />
      </MenuItem>
     ))}
    </Select>
    <FormHelperText>Select at least one. Join or leave <Link to='/groups'>groups</Link> at any time.</FormHelperText>
   </FormControl>
  );
 }
}


const styles = theme => ({
 formControl: {
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  width:'100%',
 },
 chip: {
  margin: 2,
 }
});

export default withStyles(styles)(GroupSelector);
