import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Link, withRouter} from 'react-router-dom';
import DemoVideos from "../../Components/DemoVideos/DemoVideos";
import {getIdbySegment} from "../../redux/authActions";
import OndemandVideo from "@material-ui/icons/OndemandVideo";
import Typography from '@material-ui/core/Typography';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import TamFeaturesList from "../../Components/TamFeatures/TamFeaturesList";


class Homepage extends Component {
 constructor(props) {
  super(props);
  this.state = (window.innerWidth > 576) ? {displaymode: 'block'} : {displaymode: 'background'};
 }

 showVideos(e) {
  this.setState({displaymode:'block'});
  const tdata = getIdbySegment(document.location.pathname);
  tdata.page = 'demos';
  tdata.value = 'default';
  window.logUse.logEvent('view', tdata);
 }

 render() {

  return (
   <Grid container direction='row' justifyContent='space-around' alignContent='center' alignItems='center' wrap='wrap'>
    <Grid container direction='column' alignItems='center' justifyContent='space-around' item xs={12} sm={7} spacing={8} >
     <img src='/assets/BrandedChip.png' alt="logo" className={this.props.classes.logo} />
     <Grid item>
      <Typography variant='h3' style={{marginBottom:0, textAlign:'center'}}>Track Authority Music</Typography>
      <Typography variant='h5' style={{marginTop:0, marginBottom:40, textAlign:'center'}}><em>Rewarding Musical Tastes</em></Typography>
      <p style={{fontSize: 20}}><strong>A Platform for Music</strong> Events, Blogs, Stores, Casinos &amp; more.</p>
     </Grid>
     <Grid item style={{textAlign:'center', padding:0, marginBottom:30}}>
      <Link to="/group/start" style={{textDecoration:'none'}} >
       <Button disableElevation={true} color='primary' variant='contained' style={{marginRight:10}}>Start a group</Button>
      </Link>
      <Link to="/groups" style={{textDecoration:'none'}}>
       <Button disableElevation={true} color='primary' variant='contained' >Search groups</Button>
      </Link>
      {this.state.displaymode === 'background' ?
       <Button aria-label='View Demos' startIcon={<OndemandVideo/>} color='primary'
        onClick={e => this.showVideos(e)}
        variant='contained' style={{margin: 5}}>View Demos</Button> : <></>
      }
     </Grid>
    </Grid>
    <Grid item xs={12} sm={5} >
     <div className={this.state.displaymode === 'background' ? this.props.classes.vidBackground : this.props.classes.vidBlock}>
      <DemoVideos displaymode={this.state.displaymode} windowHeight={this.props.windowHeight}
       windowWidth={this.props.windowWidth} />
     </div>
    </Grid>
    <Grid item xs={12} style={{paddingTop:40}}>
     <TamFeaturesList onSelect={type => this.props.history.push('/group/start?tab=bytype&type='+type)} />
    </Grid>
   </Grid>
  );
 }
}

const styles = theme => ({
 logo : {
  width:'80%',
  maxWidth:300,
  margin:'0 auto'
 },
 vidBackground: {
  zIndex:-1,
  position: 'fixed',
  right: 0,
  left:0,
  top:0,
  opacity:.2,
  minWidth: '100%',
  minHeight: '100%'
 }
});

Homepage.defaultProps = {
 windowWidth:window.innerWidth,
 windowHeight:window.innerHeight,
};

Homepage.propTypes = {
 windowHeight:PropTypes.number,
 windowWidth:PropTypes.number
};

export default withStyles(styles)(withRouter(Homepage));
